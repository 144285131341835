export const ALWAYS_SHOW_LISSA_T_A_C_FLAG = "alwaysShowLissaTaC";
export const CHECKOUT_PRODUCT_FRUITS_ENABLED_FLAG = "checkout.productfruits";
export const DISCOUNTS_PUBLIC_RELEASE_FLAG =
  "DiscountCodeAtCheckoutPublicRelease";
export const EC_PORTAL_ASSIGN_DEVICE_TO_USERS_ENABLED_FLAG =
  "AssignDeviceToUsers";
export const EC_PORTAL_EDIT_PAYMENT_METHOD_ENABLED_FLAG =
  "ecPortal.editPaymentMethod";
export const EC_PORTAL_END_OF_TERM_MANAGEMENT_ENABLED_FLAG =
  "EndCustomerEndOfTermManagementEnabled";
export const EC_PORTAL_PRODUCT_FRUITS_ENABLED_FLAG = "ECPortalProductFruits";
export const HAS_REQUEST_BACKFILL_ACTION_FLAG =
  "hasRequestEndCustomerInvoiceBackfillAction";
export const INTERNATIONAL_SHIPPING_FLAG = "international-shipment";
export const IS_ABLE_TO_ADD_PAYMENT_METHOD_ENABLED_FLAG =
  "isAbleToAddPaymentMethodInECPortal";
export const IS_INVOICE_BACKFILL_PENDING_FLAG =
  "isEndCustomerInvoiceBackfillPending";
export const IS_ORDER_BACKFILL_PENDING_FLAG =
  "isEndCustomerOrderBackfillPending";
export const MULTIPLIER_CUSTOM_PRODUCT_CARD_TEXT_ENABLED =
  "ShowMultiplierCustomProductCardText";
export const ORDERS_ENABLED_FLAG = "OrdersPublicRelease";
export const PAYMENTS_ENABLED_FLAG = "PaymentsPublicRelease";
export const PRODUCT_CARD_OPTIONS_ENABLED_FLAG =
  "topiTool.showProductCardOptions";
export const SEE_ALL_OFFERS_RELEASE_FLAG = "SeeAllOffersRelease";
export const SEGMENT_ENABLED_FLAG = "logToSegment";
export const SELLER_EDIT_OFFER_ENABLED_FLAG = "seller_edit_offer_enabled";
export const SELLER_PAGE_PRODUCT_FRUITS = "seller.productfruits";
export const SELLER_SHOW_EGIS_ORDER_URL = "ShowEGISOrderUrl";
export const SENTRY_ENABLED_FLAG = "sentryEnabledInFront";
export const SHOW_ALL_AVAILABLE_TENURES_FLAG =
  "topiTool.showAllAvailableTenures";
export const SHOW_MULTIPLIER_PRICING_UPDATE_BANNER_ENABLED =
  "ShowMultiplierPricingUpdateBanner";
export const TOPI_CARE_ENABLED_FLAG = "TopiCare";

export const STRIPE_PAYMENT_METHOD_ENABLED_FLAG =
  "PSPPaymentMethodPublicRelease";
export const DAAS_EXPERIMENT_ENABLED_FLAG = "DaasExperiment";
export const DUPLICATE_OUT_OF_CATALOG_PRODUCT_ENABLED =
  "SellerAppAddOOCItemByDuplicating";
export const EDIT_OUT_OF_CATALOG_PRODUCT_ENABLED = "SellerAppEditOOCItem";
export const CUSTOMER_PROVIDES_SERIAL_NUMBER_RELEASE_FLAG =
  "customerProvidesSerialNumberRelease";
export const UNIFIED_BILLING_EMAIL_ADDRESS_FLAG = "UnifiedBillingEmailAddress";
export const PDF_READER_V2_FLAG = "PDFReaderV2";

export const CHECKOUT_ID_VERIFICATION_ENABLED_FLAG = "IDVerificationEnabled";
export const SHOW_IDV2_REQUIRED_PAGE_FLAG = "ShowIDV2RequiredPage";
